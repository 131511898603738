.top_btns[data-v-f2f19db0] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.filter-item1[data-v-f2f19db0] {
  margin-right: 10px;
}
.item_icon[data-v-f2f19db0] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-f2f19db0] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-f2f19db0] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}