.top_btns[data-v-705bd91a] {
  margin-bottom: 40px;
}
.top_btns .left_box[data-v-705bd91a] {
  float: left;
}
.top_btns .right_box[data-v-705bd91a] {
  float: right;
  text-align: right;
}
.filter-item1[data-v-705bd91a] {
  margin-right: 10px;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-705bd91a] {
  color: white !important;
  width: 10px;
}
.none-border .div_p[data-v-705bd91a] {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 5px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border .div_p[data-v-705bd91a]:last-child {
  border-bottom: none;
}
.item_icon[data-v-705bd91a] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-705bd91a] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-705bd91a] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-705bd91a] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-705bd91a] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-705bd91a] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-705bd91a] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-705bd91a] {
  color: #e6a23c;
}
.item_icon p[data-v-705bd91a] {
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  color: #333;
}
.sbcg[data-v-705bd91a] {
  padding: 0 !important;
}
.sbyc[data-v-705bd91a] {
  color: red !important;
  padding: 0 !important;
}
.export_btn[data-v-705bd91a] {
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
[data-v-705bd91a] textarea {
  min-height: 145px !important;
}
.el-message__content a[data-v-705bd91a] {
  text-decoration: underline;
  color: #409eff;
}
