.top_btns[data-v-96abbfd2] {
  margin-bottom: 40px;
}
.top_btns .left_box[data-v-96abbfd2] {
  float: left;
}
.top_btns .right_box[data-v-96abbfd2] {
  float: right;
  text-align: right;
}
.filter-item1[data-v-96abbfd2] {
  margin-right: 10px;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-96abbfd2] {
  color: white !important;
  width: 10px;
}
.none-border .div_p[data-v-96abbfd2] {
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border .div_p[data-v-96abbfd2]:last-child {
  border-bottom: none;
}
.icon-picture[data-v-96abbfd2] {
  margin-right: 4px;
}
.item_icon[data-v-96abbfd2] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-96abbfd2] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-96abbfd2] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.export_btn[data-v-96abbfd2] {
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
.hint[data-v-96abbfd2] {
  font-size: 13px;
  color: red;
  margin-left: 5px;
}
.hint .icon-sanjiaotanhao[data-v-96abbfd2] {
  color: #e6a23c;
  margin-right: 4px;
}
